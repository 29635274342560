

//import "../css/styles.css";

import { useState, useEffect, useContext, createContext, useCallback } from "react";

import { useSession, useSessionUpdate } from "../context/SessionContext";
import { useNotificationUpdate } from "./NotificationContext";

import { useProfileUpdate } from "./ProfileContext";

import { fetchModelResponse, fetchAssistantResponse } from "../services/fetch";


import ReportsService from "../services/reports";

import { QUESTIONS_PAUSED_NOTIFICATION, QUESTION_LIMIT_MESSAGE, SERVER_ERROR_TEXT, SERVER_ORIGIN, SERVER_WEBSOCKETS_ORIGIN, USE_ASSISTANTS } from "../common/constants";
import { MessageType, PromptType } from "../types/enums";






enum PromptTemplateType {
  EXPLICIT = "explicit",
  GENERATED = "generated",
}

interface PromptTemplate {
  id: string;
  name: string;
  description: string;
  template: string;
  response?: string;
  category: string;
}

interface DynamicPromptData {
  currentSlideNumber?: number;
  presentationContext?: string;
  transcript?: string;
  senderName?: string;
  studentName?: string;

  basicLectureInfo?: string;
  curriculum?: string;

  lecture?: string;
  lastLectureRound?: string;
}




interface PromptContextType {
  promptTemplates: PromptTemplate[];
}

interface PromptUpdateContextType {
  handleGeneralPrompt: (prompt: string) => any; //Promise<string | undefined>;
  handleQuestion: (question: string, senderName: string, selectedFile?: File) => any; //Promise<string | undefined>;
  handleGeneratePrompt: (prompt: string) => any; // ...
  handleReviseGeneratedPrompt: (prompt: string, generatedPrompt: string) => any; // ...
  handleGenerateAndRevisePrompt: (prompt: string, revisePrompt: string) => any; // ...

  handleExecutePrompt: (prompt: string) => any; // ...
  handleCompileAndExecutePrompt: (promptId: string, dynamicData: DynamicPromptData, promptType?: PromptTemplateType) => any; // ...

  getPromptCategories: (templateType?: PromptTemplateType) => string[];
  getPromptTemplate: (id: string, templateType?: PromptTemplateType) => PromptTemplate | undefined;
  getPromptTemplatesForCategory: (category: string, templateType?: PromptTemplateType) => PromptTemplate[];
  getCompiledPrompt: (id: string, data?: DynamicPromptData, templateType?: PromptTemplateType) => string;
  updatePromptTemplate: (id: string, newTemplate: string, templateType?: PromptTemplateType) => void;
  updatePromptTemplateResponse: (id: string, newResponse: string, templateType?: PromptTemplateType) => void;

  handleCompileGenerateAndRevisePrompts: (draftPromptId: string, draftDynamicData: DynamicPromptData, revisePromptId: string, reviseDynamicData: DynamicPromptData) => any;
  handleCompileGenerateAndPublishPrompts: (draftPromptId: string, draftDynamicData: DynamicPromptData, publishPromptId: string, publishDynamicData: DynamicPromptData) => any;
}




// TODO: Consider moving to separate file or find different / better way to organize
const defaultPrompts: PromptTemplate[] = require("../data/prompts/explicitPrompts.json");
const defaultPromptsToGeneratePrompt: PromptTemplate[] = require("../data/prompts/generatedPrompts.json");


const PromptContext = createContext<PromptContextType | null>(null);
const PromptUpdateContext = createContext<PromptUpdateContextType | null>(null);


function usePrompt() {
  const context = useContext(PromptContext);

  if (!context) throw new Error("usePrompt must be used within PromptContextProvider");

  return context;
}

function usePromptUpdate() {
  const context = useContext(PromptUpdateContext);

  if (!context) throw new Error("usePromptUpdate must be used within PromptContextProvider");

  return context;
}


function PromptContextProvider( {children}: ComponentProps ) {

  const [ promptTemplates, setPromptTemplates ] = useState<PromptTemplate[]>( defaultPrompts );
  const [ promptTemplatesGenerated, setPromptTemplatesGenerated ] = useState<PromptTemplate[]>( defaultPromptsToGeneratePrompt );


  const { questionsAllowed } = useSession();
  
  const { setSlideReadyToMoveOn, getCurrentSlideNumber, getSessionTranscript, getSessionPresentationContext, getDefaultAppStateHandlerConfig, setChatMessagePending } = useSessionUpdate();

  const { postNotification } = useNotificationUpdate();

  const { getHumanStudentName } = useProfileUpdate();



  useEffect(() => {

    const fetchGeneratedPrompts = async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/admin/prompts?promptTemplateType=GENERATED`);

      const data = await response.json();

      if( !data.prompts ) return;

      Object.keys(data.prompts).forEach( (promptId: string) => {
        updatePromptTemplate( promptId, data.prompts[promptId], PromptTemplateType.GENERATED );
      });

      console.log("(fetchPrompts) DATA:", data);
    }

    const fetchExplicitPrompts = async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/admin/prompts?promptTemplateType=EXPLICIT`);

      const data = await response.json();

      if( !data.prompts ) return;

      Object.keys(data.prompts).forEach( (promptId: string) => {
        updatePromptTemplate( promptId, data.prompts[promptId], PromptTemplateType.EXPLICIT );
      });
    }

    fetchGeneratedPrompts();
    fetchExplicitPrompts();

  }, []);





  // ============================= PROMPT MANIPULATION =============================
  const getPromptCategories = useCallback((templateType: PromptTemplateType = PromptTemplateType.EXPLICIT) => {
    switch( templateType ) {
      case PromptTemplateType.GENERATED:
        return Array.from(new Set(promptTemplatesGenerated.map(p => p.category)));
      default:
        return Array.from(new Set(promptTemplates.map(p => p.category)));
    }
  }, [promptTemplates, promptTemplatesGenerated]);



  const getPromptTemplate = useCallback((id: string, templateType: PromptTemplateType = PromptTemplateType.EXPLICIT): PromptTemplate | undefined => {
    console.log("(getPromptTemplate) id:", id);
    console.log("(getPromptTemplate) templateType:", templateType);
    console.log("(getPromptTemplate) promptTemplates:", promptTemplates);
    console.log("(getPromptTemplate) promptTemplatesGenerated:", promptTemplatesGenerated);
    switch( templateType ) {
      case PromptTemplateType.GENERATED:
        return promptTemplatesGenerated.find(p => p.id === id);
      default:
        return promptTemplates.find(p => p.id === id);
    }
  }, [promptTemplates, promptTemplatesGenerated]);

  const getPromptTemplatesForCategory = useCallback((category: string, templateType: PromptTemplateType = PromptTemplateType.EXPLICIT): PromptTemplate[] => {
    switch( templateType ) {
      case PromptTemplateType.GENERATED:
        return promptTemplatesGenerated.filter(p => p.category === category);
      default:
        return promptTemplates.filter(p => p.category === category);
    }
  }, [promptTemplates, promptTemplatesGenerated]);

  // TODO: Make parameters an Object instead to allow removal of undesired parameters via just not specifying ... Instead of having to pass fillers to get to latter parameters...
  const getCompiledPrompt = useCallback((id: string, dynamicData?: DynamicPromptData, templateType: PromptTemplateType = PromptTemplateType.EXPLICIT): string => {
    const prompt = getPromptTemplate(id, templateType);
    if( !prompt ) return "";

    if( !dynamicData ) return prompt.template;


    let compiledTemplate = prompt.template;
    
    // Replace dynamic values
    if (dynamicData.currentSlideNumber !== undefined) {
      compiledTemplate = compiledTemplate.replace("{slideNumber}", dynamicData.currentSlideNumber.toString());
    }
    if (dynamicData.presentationContext) {
      compiledTemplate = compiledTemplate.replace("{presentationContext}",
        condensePresentationContext(dynamicData.presentationContext));
    }
    if (dynamicData.transcript) {
      compiledTemplate = compiledTemplate.replace("{transcript}", 
        condenseTranscript(dynamicData.transcript));
    }
    if (dynamicData.senderName) {
      compiledTemplate = compiledTemplate.replace("{senderName}", dynamicData.senderName);
    }
    if (dynamicData.studentName) {
      compiledTemplate = compiledTemplate.replace("{studentName}", dynamicData.studentName);
    }

    if (dynamicData.basicLectureInfo) {
      compiledTemplate = compiledTemplate.replace("{basicLectureInfo}", dynamicData.basicLectureInfo);
    }
    if (dynamicData.curriculum) {
      compiledTemplate = compiledTemplate.replace("{curriculum}", dynamicData.curriculum);
    }

    if( dynamicData.lecture ) {
      compiledTemplate = compiledTemplate.replace("{lecture}", dynamicData.lecture);
    }
    if( dynamicData.lastLectureRound ) {
      compiledTemplate = compiledTemplate.replace("{lastLectureRound}", dynamicData.lastLectureRound);
    }

    return compiledTemplate;
  }, [getPromptTemplate]);



  const updatePromptTemplate = useCallback((id: string, newTemplate: string, templateType: PromptTemplateType = PromptTemplateType.EXPLICIT) => {
    //console.log( "Updating Prompt Template:", id, newTemplate );

    switch( templateType ) {
      case PromptTemplateType.GENERATED:
        setPromptTemplatesGenerated(prev => 
          prev.map(prompt => 
            prompt.id === id ? { ...prompt, template: newTemplate } : prompt
          )
        );
        break;

      default:
        setPromptTemplates(prev => 
          prev.map(prompt => 
            prompt.id === id ? { ...prompt, template: newTemplate } : prompt
          )
        );
    }
  }, [promptTemplates, promptTemplatesGenerated]); // TODO: Should this state be here as modifying it in method...?

  const updatePromptTemplateResponse = useCallback((id: string, newResponse: string, templateType: PromptTemplateType = PromptTemplateType.EXPLICIT) => {
    //console.log("Updating Prompt Template Response:", id, newResponse);

    switch( templateType ) {
      case PromptTemplateType.GENERATED:
        setPromptTemplatesGenerated(prev =>
          prev.map(prompt =>
            prompt.id === id ? { ...prompt, response: newResponse } : prompt
          )
        );
        console.log("(updatePromptTemplateResponse) GENERATED PROMPT RESPONSE UPDATED:", id, newResponse);
        break;

      default:
        setPromptTemplates(prev =>
          prev.map(prompt =>
            prompt.id === id ? { ...prompt, response: newResponse } : prompt
          )
        );
    }
  }, [promptTemplates, promptTemplatesGenerated]);



  // ============================= RESPONSE HANDLING =============================
  const getModelResponse = useCallback(async ( context: string, prompt: string, promptType: string = PromptType.QUESTION_ANSWER ) => {

    try {

      setChatMessagePending( true );
      const modelResponse = await fetchModelResponse( promptType, context, prompt, getDefaultAppStateHandlerConfig() );
      setChatMessagePending( false );

      if( !modelResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return modelResponse;
    }
    catch( error ) {
      console.error( "Error:", error );
    }
  }, []);

  const getAssistantResponse = useCallback(async ({ context, prompt, file, promptType = PromptType.QUESTION_ANSWER, messageType = MessageType.CHAT }: any) => {

    try {
      const assistantResponse = await fetchAssistantResponse( promptType, context, prompt, file, getDefaultAppStateHandlerConfig() );

      if( !assistantResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return assistantResponse;
    }
    catch (error) {
      console.error('Error:', error);
    }
  }, []);





  // ============================= PROMPT HANDLING =============================
  const handleQuestion = useCallback(async ( question: string, senderName: string, selectedFile?: File ) => { // TODO: Assumes it's a question for now...

    if( !questionsAllowed ) {
      postNotification( QUESTIONS_PAUSED_NOTIFICATION );
      return;
    }

    //setSlideFakeStudentShouldAsk( false );
    //setSlideReadyToMoveOn( false ); // "speak" does this too, BUT, that waits on Server's Response for Model -- May take too long


    const data: DynamicPromptData = {
      currentSlideNumber: getCurrentSlideNumber(),
      presentationContext: condensePresentationContext(getSessionPresentationContext()),
      transcript: condenseTranscript(getSessionTranscript()),
      studentName: getHumanStudentName(),
      senderName,
    };

    /*
    let context = getStudentPromptContextPreliminary( getCurrentSlideNumber() );
    context += ` \n${getPresentationContextPrompt( getSessionPresentationContext() )}`;
    context += ` \n${getTranscriptContextPrompt( getSessionTranscript() )}`;
    context += ` \n${getSenderNamePrompt( senderName )}`;
    context += ` \n${getStudentPromptTypePrompt()}`;
    const prompt = question;
    */

    let context = getCompiledPrompt("studentPromptContextPreliminary", data);
    context += ` \n${getCompiledPrompt("presentationContext", data)}`;
    context += ` \n${getCompiledPrompt("transcriptContext", data)}`;
    context += ` \n${getCompiledPrompt("senderContext", data)}`;
    context += ` \n${getCompiledPrompt("studentNameContext", data)}`;
    context += ` \n${getCompiledPrompt("promptTypeContext", data)}`;

    const prompt = question;

    console.log("(handleQuestion) FINAL CONTEXT:", context );


    let message: string;
    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, file: selectedFile };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt );
    }

    return message;
  }, [questionsAllowed, postNotification, getCurrentSlideNumber, getSessionPresentationContext, getSessionTranscript, getHumanStudentName, getCompiledPrompt]);

  const handleGeneralPrompt = useCallback(async ( prompt: string ) => {

    if( !prompt ) return;

    const data: DynamicPromptData = {
      currentSlideNumber: getCurrentSlideNumber(),
      presentationContext: condensePresentationContext(getSessionPresentationContext()),
      transcript: condenseTranscript(getSessionTranscript()),
      studentName: getHumanStudentName(),
    };
    /*
    let context = getGeneralPromptContextPreliminary();
    context += ` \n${getPresentationContextPrompt( getSessionPresentationContext() )}`;
    context += ` \n${getTranscriptContextPrompt( getSessionTranscript() )}`;
    context += ` \n${getStudentNamePrompt( getHumanStudentName() )}`;
    context += ` \n${getResponseSantizerPrompt()}`;
    */

    let context = getCompiledPrompt("generalPromptContextPreliminary", data);
    context += ` \n${getCompiledPrompt("presentationContext", data)}`;
    context += ` \n${getCompiledPrompt("transcriptContext", data)}`;
    context += ` \n${getCompiledPrompt("studentNameContext", data)}`;
    context += ` \n${getCompiledPrompt("responseSanitizer", data)}`;


    console.log("(handleGeneralPrompt) FINAL CONTEXT:", context );


    let message: string;

    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, messageType: MessageType.CHAT };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt, PromptType.GENERAL );
    }

    return message;
    //return "";
  }, [getCompiledPrompt, getAssistantResponse]);


  const handleGeneratePrompt = useCallback(async (prompt: string) => {
    const context = "Provide a recommended prompt. Create a prompt. Do not mention creating the prompt or acknowledging this request. Create and return the prompt fully ready to use, no filler text";

    // TODO: ERROR HANDLE IF prompt is empty... Because else it causes very strange behavior as AI generates random response
    if( !prompt ) {
      console.warn("Prompt is empty when handling generate prompt");
      return "";
    }

    const generatedPrompt = await getModelResponse( context, prompt, PromptType.GENERATE );

    // TODO: Want update in some way so can view in separate section/category of ADMIN PANEL (allow way to modify them as well - i.e. should always pull from whatever context is used to populate Admin Panel and update that context from here or the caller ? Prob da caller)
    // TODO: Student Bot needs to make occasionall "creative mistakes", need update AIBots for this prob (generateStudentResponse)

    return generatedPrompt;
  }, []);

  const handleExecutePrompt = useCallback(async (prompt: string) => {
    const context = "Execute this prompt. Do not mention executing the prompt or acknowledging this request. Execute and return the response fully ready to use, no filler text";
    const executedPrompt = await getModelResponse( context, prompt, PromptType.GENERAL );
    return executedPrompt;
  }, []);


  const handleReviseGeneratedPrompt = useCallback(async (revisePrompt: string, generatedPrompt: string) => {
    const defaultRevisePrompt = "Revise this prompt. Do not mention revising the prompt or acknowledging this request. Strictly revise and return the prompt fully ready to use, no filler text";
    const context = `You were given this prompt, this is what needs revised: ${generatedPrompt}`;

    revisePrompt = revisePrompt ? revisePrompt : defaultRevisePrompt;
    const revisedPrompt = await getModelResponse( context, revisePrompt, PromptType.GENERATE );

    return revisedPrompt;
  }, []);

  const handleGenerateAndRevisePrompt = useCallback(async (prompt: string, revisePrompt: string) => {
      let generatedPrompt = await handleGeneratePrompt( prompt );
      generatedPrompt = await handleReviseGeneratedPrompt( revisePrompt, generatedPrompt );

      return generatedPrompt;
  }, []);

  const handleCompileGenerateAndRevisePrompts = useCallback(async (draftPromptId: string, draftDynamicData: DynamicPromptData, revisePromptId: string, reviseDynamicData: DynamicPromptData) => {
      const draftPrompt = getCompiledPrompt( draftPromptId, draftDynamicData, PromptTemplateType.GENERATED );
      const revisePrompt = getCompiledPrompt( revisePromptId, reviseDynamicData, PromptTemplateType.GENERATED );

      let draftedPrompt = await handleGeneratePrompt( draftPrompt );
      let revisedPrompt = await handleReviseGeneratedPrompt( revisePrompt, draftedPrompt );

      updatePromptTemplateResponse( draftPromptId, draftedPrompt, PromptTemplateType.GENERATED );
      updatePromptTemplateResponse( revisePromptId, revisedPrompt, PromptTemplateType.GENERATED );

      console.log(`(handleCompileGenerateAndRevisePrompts - ${draftPromptId} - ${revisePromptId}) DRAFT PROMPT:`, draftPrompt);
      console.log(`(handleCompileGenerateAndRevisePrompts - ${draftPromptId} - ${revisePromptId}) REVISE PROMPT:`, revisePrompt);
      console.log(`(handleCompileGenerateAndRevisePrompts - ${draftPromptId} - ${revisePromptId}) REVISED PROMPT:`, revisedPrompt);

      return revisedPrompt;
  }, [])

  const handleCompileGenerateAndPublishPrompts = useCallback(async (draftPromptId: string, draftDynamicData: DynamicPromptData, publishPromptId: string, publishDynamicData: DynamicPromptData) => {
      const draftPrompt = getCompiledPrompt( draftPromptId, draftDynamicData, PromptTemplateType.GENERATED );
      const publishPrompt = getCompiledPrompt( publishPromptId, publishDynamicData, PromptTemplateType.GENERATED );
      const generatedPrompt = await handleGeneratePrompt( draftPrompt );

      // TODO: Not really "publishedPrompt" if only revising ??
      const publishedPrompt = await handleReviseGeneratedPrompt( publishPrompt, generatedPrompt );

      //updatePromptTemplateResponse( draftPromptId, generatedPrompt, PromptTemplateType.GENERATED );
      updatePromptTemplateResponse( draftPromptId, generatedPrompt, PromptTemplateType.GENERATED );
      updatePromptTemplateResponse( publishPromptId, publishedPrompt, PromptTemplateType.GENERATED );

      console.log(`(handleCompileGenerateAndPublishPrompts - ${draftPromptId} - ${publishPromptId}) DRAFT PROMPT:`, draftPrompt);
      console.log(`(handleCompileGenerateAndPublishPrompts - ${draftPromptId} - ${publishPromptId}) PUBLISH PROMPT:`, publishPrompt);
      console.log(`(handleCompileGenerateAndPublishPrompts - ${draftPromptId} - ${publishPromptId}) GENERATED PROMPT:`, generatedPrompt);
      console.log(`(handleCompileGenerateAndPublishPrompts - ${draftPromptId} - ${publishPromptId}) PUBLISHED PROMPT:`, publishedPrompt);

      return publishedPrompt;
  }, [])

  const handleCompileAndExecutePrompt = useCallback(async (promptId: string, dynamicData: DynamicPromptData, promptType: PromptTemplateType = PromptTemplateType.EXPLICIT) => {
    const compiledPrompt = getCompiledPrompt( promptId, dynamicData, promptType );
    console.log("(handleCompileAndExecutePrompt) COMPILED PROMPT:", compiledPrompt);
    const executedPrompt = await handleExecutePrompt( compiledPrompt );
    console.log("(handleCompileAndExecutePrompt) EXECUTED PROMPT:", executedPrompt);
    return executedPrompt;
  }, [getCompiledPrompt, handleExecutePrompt]);

  // =============================================================================



  const contextValue: PromptContextType = {
    promptTemplates
  };

  const updateContextValue: PromptUpdateContextType = {
    handleQuestion, handleGeneralPrompt,
    handleGeneratePrompt, handleReviseGeneratedPrompt, handleGenerateAndRevisePrompt,
    handleExecutePrompt, handleCompileAndExecutePrompt,

    getCompiledPrompt, getPromptCategories,
    getPromptTemplate, getPromptTemplatesForCategory,
    updatePromptTemplate, updatePromptTemplateResponse,
    handleCompileGenerateAndRevisePrompts, handleCompileGenerateAndPublishPrompts,
  };



  return (
    /* TODO: PromptContext passes FUNCTIONS down that may be better in PromptUpdateContext, as that's the pattern I use in other Contexts - i.e. if a method that is called, it is passed thorugh the Update Context */
    <PromptContext.Provider value={ contextValue } >
      <PromptUpdateContext.Provider value={ updateContextValue } >
        {children}
      </PromptUpdateContext.Provider>
    </PromptContext.Provider>
  );

}



function condenseTranscript( currentTranscript: string ) {

  const TRANSCRIPT_CHARACTER_LIMIT = 9000;
  if( currentTranscript.length > TRANSCRIPT_CHARACTER_LIMIT ) {
    let startIndex = currentTranscript.length - TRANSCRIPT_CHARACTER_LIMIT;

    return currentTranscript.slice( startIndex );
  }

  return currentTranscript;
}

function condensePresentationContext( currentPresentationContext: string ) {

  const PRESENTATION_CONTEXT_CHARACTER_LIMIT = 5000;
  if( currentPresentationContext.length > PRESENTATION_CONTEXT_CHARACTER_LIMIT ) {
    let startIndex = currentPresentationContext.length - PRESENTATION_CONTEXT_CHARACTER_LIMIT;

    return currentPresentationContext.slice( startIndex );
  }

  return currentPresentationContext;
}







export {
  PromptTemplateType,

  PromptContextProvider,
  usePrompt, usePromptUpdate,
};

